





































































































import Vue from 'vue';
import GlobalEvents from 'vue-global-events';
import { RootMutationTypes } from '@/types';

export default Vue.extend({
	components: { GlobalEvents },
	props: {
		backButton: {
			type: Boolean,
			default: false
		},
		pin: {
			type: Number
		},
		gameInfo: {
			type: Boolean,
			default: false
		},
		closeButton: {
			type: Boolean,
			default: false
		},
		nextButton: {
			type: Boolean,
			default: false
		},
		logo: {
			type: Boolean,
			default: true
		},
		timer: {
			type: Number,
			default: -1
		},
		confirmModal: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		modal: null as null | string
	}),
	methods: {
		close(eventName: string) {
			if (this.confirmModal) {
				this.$store.commit(RootMutationTypes.TOGGLE_MODAL);
				this.modal = eventName;
				return;
			}
			this.$emit(eventName);
		},
		exit(emit = false) {
			if (emit) {
				this.$emit(this.modal as string);
			}
			this.$store.commit(RootMutationTypes.TOGGLE_MODAL);
			this.modal = null;
		}
	}
});
