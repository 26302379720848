var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solo-game"},[_c('HostHeader',{attrs:{"close-button":"","next-button":""},on:{"close":function($event){return _vm.mainMenu()},"next":function($event){return _vm.goNext()}}}),_c('main',{class:[
			'site-main site-main_full',
			{ 'has-modal': _vm.$store.state.modal }
		]},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"l-answer-animation d-block d-lg-none"},[(_vm.isCorrect)?_c('div',{staticClass:"correct-animation"},[_c('v-lottie-player',{attrs:{"width":"220","height":"200","path":"https://assets9.lottiefiles.com/packages/lf20_lki2ruq1.json","background":"transparent","speed":"1","loop":"","autoplay":""}})],1):_c('div',{staticClass:"incorrect-animation"},[_c('v-lottie-player',{attrs:{"width":"220","height":"200","path":"https://assets10.lottiefiles.com/packages/lf20_3uomvin8.json","background":"transparent","speed":"1","loop":"","autoplay":""}})],1)]),_c('div',{staticClass:"l-answer-status"},[(_vm.isCorrect)?_c('h2',{staticClass:"answer-frame answer-frame--empty correct"}):_c('h2',{staticClass:"answer-frame answer-frame--empty incorrect"})]),(
					_vm.currentTrivia.answers.length > 2 &&
						_vm.currentTrivia.images.length === 0
				)?_c('div',{staticClass:"l-qna-container l-qna-container_answers"},[_c('div',{staticClass:"l-question-box l-question-box_answers"},[_c('p',{staticClass:"h1",domProps:{"innerHTML":_vm._s(_vm.currentTrivia.question)}})]),_c('div',{staticClass:"l-question-answers l-question-answers_abcd"},_vm._l((_vm.currentTrivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer-item"},[_c('div',{class:[
								'btn btn-answer',
								{
									correct:
										_vm.currentTrivia.correct === answer
								},
								{
									incorrect:
										_vm.currentTrivia.correct !== answer
								},
								{ selected: answer === _vm.selectedAnswer }
							]},[_vm._v(" "+_vm._s(answer)+" ")])])}),0)]):_vm._e(),(
					_vm.currentTrivia.answers.length > 2 &&
						_vm.currentTrivia.images.length === 1
				)?_c('div',{staticClass:"l-qna-container l-qna-container_answers"},[_c('div',{staticClass:"l-question-box-2column"},[_c('div',{staticClass:"image-column"},[_c('figure',{staticClass:"question-img-lg"},[_c('img',{attrs:{"src":require(("@/assets/images/" + (_vm.currentTrivia.images[0]))),"alt":""}})])]),_c('div',{staticClass:"question-column"},[_c('p',{staticClass:"question-copy",domProps:{"innerHTML":_vm._s(_vm.currentTrivia.question)}})])]),_c('div',{staticClass:"l-question-answers l-question-answers_abcd"},_vm._l((_vm.currentTrivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer-item"},[_c('div',{class:[
								'btn btn-answer',
								{
									correct:
										_vm.currentTrivia.correct === answer
								},
								{
									incorrect:
										_vm.currentTrivia.correct !== answer
								},
								{ selected: answer === _vm.selectedAnswer }
							]},[_vm._v(" "+_vm._s(answer)+" ")])])}),0)]):_vm._e(),(
					_vm.currentTrivia.answers.length === 2 &&
						_vm.currentTrivia.images.length === 0
				)?_c('div',{staticClass:"l-qna-container l-qna-container_answers"},[_c('div',{staticClass:"l-question-box l-question-box_answers"},[_c('p',{staticClass:"h1",domProps:{"innerHTML":_vm._s(_vm.currentTrivia.question)}})]),_c('div',{staticClass:"l-question-answers"},_vm._l((_vm.currentTrivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer-item"},[_c('div',{class:[
								'btn btn-answer',
								{
									'btn-answer-true':
										answer.toLowerCase() === 'true'
								},
								{
									'btn-answer-false':
										answer.toLowerCase() === 'false'
								},
								{
									correct:
										_vm.currentTrivia.correct === answer
								},
								{
									incorrect:
										_vm.currentTrivia.correct !== answer
								},
								{ selected: answer === _vm.selectedAnswer }
							]},[_vm._v(" "+_vm._s(answer)+" ")])])}),0)]):_vm._e(),(
					_vm.currentTrivia.answers.length === 2 &&
						_vm.currentTrivia.images.length === 1
				)?_c('div',{staticClass:"l-qna-container l-qna-container_answers"},[_c('div',{staticClass:"l-question-box-2column"},[_c('div',{staticClass:"image-column"},[_c('figure',{staticClass:"question-img-lg"},[_c('img',{attrs:{"src":require(("@/assets/images/" + (_vm.currentTrivia.images[0]))),"alt":""}})])]),_c('div',{staticClass:"question-column"},[_c('p',{staticClass:"question-copy",domProps:{"innerHTML":_vm._s(_vm.currentTrivia.question)}})])]),_c('div',{staticClass:"l-question-answers"},_vm._l((_vm.currentTrivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer-item"},[_c('div',{class:[
								'btn btn-answer',
								{
									'btn-answer-true':
										answer.toLowerCase() === 'true'
								},
								{
									'btn-answer-false':
										answer.toLowerCase() === 'false'
								},
								{
									correct:
										_vm.currentTrivia.correct === answer
								},
								{
									incorrect:
										_vm.currentTrivia.correct !== answer
								},
								{ selected: answer === _vm.selectedAnswer }
							]},[_vm._v(" "+_vm._s(answer)+" ")])])}),0)]):_vm._e(),_c('div',{staticClass:"l-main-cta l-main-cta_answers d-block d-lg-none"},[_c('button',{staticClass:"btn btn-yellow btn-rounded btn-large btn-wide",on:{"click":function($event){return _vm.goNext()}}},[_vm._v(" Next ")])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-main-logo l-main-logo_medium d-block d-lg-none"},[_c('a',{staticClass:"brand-logo-medium",attrs:{"href":"#"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/dist/images/logo.svg","alt":""}})])])}]

export { render, staticRenderFns }