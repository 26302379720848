import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { RootMutationTypes, RootState } from '@/types';
import { JoinModule } from '@/store/join-module';
import { HostModule } from '@/store/host-module';
import { SoloModule } from '@/store/solo-module';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	modules: ['host', 'solo', 'join']
});

export default new Vuex.Store<RootState>({
	modules: {
		host: HostModule,
		solo: SoloModule,
		join: JoinModule
	},
	state: {
		modal: false
	},
	mutations: {
		[RootMutationTypes.TOGGLE_MODAL](state) {
			state.modal = !state.modal;
		}
	},
	actions: {},
	getters: {},
	plugins: [vuexLocal.plugin]
});
