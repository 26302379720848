import Vue, { PropType } from 'vue';
import { TriviaQuestion } from '@/types';
import { size } from 'lodash-es';

export default Vue.extend({
	props: {
		trivia: Object as PropType<TriviaQuestion>
	},
	methods: {
		countAnswers(answer: string | boolean | number) {
			let counter = 0;
			for (const playerId in this.$store.state.host.players) {
				const player = this.$store.state.host.players[playerId];
				if (
					answer ===
					player.answers[this.$store.state.host.currentQuestionIndex]
				) {
					counter++;
				}
			}
			return counter;
		},
		countPercentage(answer: string | boolean | number) {
			return (
				this.countAnswers(answer) / size(this.$store.state.host.players)
			);
		}
	}
});
