import {
	SoloMutationTypes,
	RootState,
	TriviaQuestion,
	SentimentCapture
} from '@/types';
import { Module } from 'vuex';
import { shuffle } from 'lodash-es';
import questions from '@/assets/trivia.json';

const initSoloState = (): SoloState => ({
	questions: [] as Array<TriviaQuestion>,
	answers: [] as Array<string | number>,
	currentQuestionIndex: 0,
	sentiment: SentimentCapture.NOT_SET
});

interface SoloState {
	questions: Array<TriviaQuestion>;
	answers: Array<string | number>;
	currentQuestionIndex: number;
	sentiment: SentimentCapture;
}

export const SoloModule: Module<SoloState, RootState> = {
	namespaced: true,
	state: initSoloState(),
	mutations: {
		[SoloMutationTypes.SET_QUESTIONS](state) {
			const gambleQuestionsPerGame = 3;
			const soloQuestionsPerGame = Number.parseInt(
				process.env.VUE_APP_SOLO_QUESTIONS as string
			);

			let gambleQuestions = questions.filter(
				(question: TriviaQuestion) => question.isGamble
			);
			gambleQuestions = shuffle(gambleQuestions).slice(
				0,
				gambleQuestionsPerGame
			);

			let otherQuestions = questions.filter(
				(question: TriviaQuestion) => !question.isGamble
			);
			otherQuestions = shuffle(otherQuestions).slice(
				0,
				soloQuestionsPerGame - gambleQuestionsPerGame
			);

			gambleQuestions = shuffle(gambleQuestions);
			otherQuestions = shuffle(otherQuestions);

			const finalQuestions: Array<TriviaQuestion> = [];
			otherQuestions.forEach((question, index) => {
				finalQuestions.push(question);
				const when = Math.floor(
					otherQuestions.length / gambleQuestionsPerGame
				);
				if (index % when === 0) {
					const q = gambleQuestions.shift() as TriviaQuestion;
					if (q) {
						finalQuestions.push(q);
					}
				}
			});
			state.questions = finalQuestions;
		},
		[SoloMutationTypes.ANSWER_QUESTION](state, answer) {
			state.answers[state.currentQuestionIndex] = answer;
		},
		[SoloMutationTypes.NEXT_QUESTION](state) {
			state.currentQuestionIndex++;
		},
		[SoloMutationTypes.SET_SENTIMENT](state, sentiment: SentimentCapture) {
			state.sentiment = sentiment;
		},
		[SoloMutationTypes.RESET](state) {
			Object.assign(state, initSoloState());
		}
	},
	getters: {
		currentTrivia: (state) => state.questions[state.currentQuestionIndex],
		currentQuestionNo: (state) => state.currentQuestionIndex + 1,
		questionsCount: (state) => state.questions.length
	},
	actions: {
		reset: ({ commit }) => commit(SoloMutationTypes.RESET)
	}
};
