

















































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import AnswerAbcd from '@/components/answer-templates/AnswerAbcd.vue';
import AnswerAb from '@/components/answer-templates/AnswerAb.vue';
import { mapGetters } from 'vuex';
import { HostMutationTypes, TriviaQuestion } from '@/types';

export default PusherHostMixin.extend({
	components: { AnswerAbcd, AnswerAb },
	computed: {
		...mapGetters('host', [
			'currentTrivia',
			'currentQuestionNo',
			'questionsCount',
			'roundNo',
			'nextRoundNo'
		])
	},
	methods: {
		connected() {
			if (!this.currentTrivia) {
				this.$router.push('/host/calculating');
				return;
			}

			this.$channel?.trigger('client-result', {
				correct: (this.currentTrivia as TriviaQuestion).correct
			});
		},
		nextQuestion() {
			// If we are one question before last one
			if (this.currentQuestionNo === this.questionsCount - 1) {
				this.$store.commit(`host/${HostMutationTypes.NEXT_QUESTION}`);
				this.$router.push('/host/question/last');
				return;
			}

			// If we answered last question, show leaderboard
			if (this.currentQuestionNo === this.questionsCount) {
				this.$router.push('/host/calculating');
				return;
			}

			if (this.nextRoundNo > this.roundNo) {
				this.$store.commit(`host/${HostMutationTypes.NEXT_QUESTION}`);
				this.$router.push('/host/leaderboard');
				return;
			}

			// Just go to the next question
			this.$store.commit(`host/${HostMutationTypes.NEXT_QUESTION}`);
			this.$router.push('/host/question');
		}
	}
});
