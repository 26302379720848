






































































































import Pusher from 'pusher-js';
import axios from 'axios';
import { JoinMutationTypes, SentimentCapture, User } from '@/types';
import {
	ApiSaveUserPayload,
	AxiosResponse,
	PusherSubscriptionSucceededPayload
} from '@/types/payloads';
import { TriviaVue } from '@/types/TriviaVue';

Pusher.logToConsole = process.env.NODE_ENV !== 'production';

const errors = {
	pin: '',
	nickname: '',
	general: ''
};

export default TriviaVue.extend({
	data: () => ({
		pin: null as number | null,
		nickname:
			process.env.NODE_ENV === 'production'
				? ''
				: ('lucien' as unknown | string),
		loading: false,
		errors
	}),
	computed: {
		hasErrors() {
			return (
				this.errors.pin !== '' ||
				this.errors.nickname !== '' ||
				this.errors.general !== ''
			);
		},
		canJoin() {
			return /^(\d{4})$/.test(`${this.pin}`) && this.nickname !== '';
		}
	},
	mounted() {
		// If there's existing player, redirect to game.
		if (this.$store.state.join.player !== null) {
			this.$router.push('/join/lobby');
		}
	},
	methods: {
		async join() {
			this.loading = true;
			this.errors = Object.assign({}, errors);

			let response: null | AxiosResponse<ApiSaveUserPayload> = null;
			try {
				response = await axios.post(
					`${process.env.VUE_APP_API}/channel/user`,
					{
						user: this.nickname,
						channel: this.pin
					}
				);
			} catch (error) {
				this.setError(
					error.response.data.message ?? error.response.statusText,
					error.response.data.code ?? 0
				);
			}

			if (response && (response.data.status ?? 'error') === 'error') {
				this.setError(
					response.data.message ?? 'Unknown error.',
					response.data.code ?? 0
				);
			}

			if (this.hasErrors) {
				this.loading = false;
				return;
			}

			this.nickname = response?.data?.user;

			const pusher = new Pusher(
				process.env.VUE_APP_PUSHER_KEY as string,
				{
					cluster: 'ap4',
					authEndpoint: `${process.env.VUE_APP_API}/auth`,
					auth: {
						params: {
							nickname: this.nickname
						}
					}
				}
			);
			TriviaVue.prototype.$pusher = pusher;

			const channel = pusher.subscribe(`presence-trivia_${this.pin}`);
			channel.bind(
				'pusher:subscription_succeeded',
				(members: PusherSubscriptionSucceededPayload) => {
					const user = {} as User;
					user.id = members.me.id;
					user.name = members.me.info.name;
					user.gameId = this.pin;
					user.points = 0;
					user.sentimentCapture = SentimentCapture.NOT_SET;

					this.$store.commit(
						`join/${JoinMutationTypes.SET_PLAYER}`,
						user
					);
					this.loading = false;
					this.$router.push('/join/lobby');
				}
			);

			TriviaVue.prototype.$channel = channel;
		},
		setError(message: string, code: number) {
			if (code >= 100 && code < 200) {
				this.errors.pin = message;
				return;
			}
			if (code >= 200) {
				this.errors.nickname = message;
				return;
			}
			this.errors.general = message;
		}
	}
});
