



























































































































import Vue from 'vue';
import { HostMutationTypes, GameTypes } from '@/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import HostHeader from '@/components/HostHeader.vue';
import { ApiCreateGamePayload, AxiosResponse } from '@/types/payloads';

export default Vue.extend({
	components: { HostHeader },
	data: () => ({
		loading: false,
		error: ''
	}),
	computed: {
		...mapGetters('host', ['isGameReady']),
		GameTypes: () => GameTypes,
		rounds: () => process.env.VUE_APP_ROUNDS
	},
	mounted() {
		if (this.isGameReady) {
			this.$router.push('/host/lobby');
		}
	},
	methods: {
		async createGame(type: GameTypes) {
			let response: null | AxiosResponse<ApiCreateGamePayload> = null;
			this.loading = true;
			this.error = '';

			try {
				this.$store.commit(
					`host/${HostMutationTypes.SET_GAME_TYPE}`,
					type
				);

				response = await axios.post(
					`${process.env.VUE_APP_API}/channel`
				);
			} catch (error) {
				this.error = error?.response?.data?.message ?? error;
			}

			if (response && (response.data.status ?? 'error') === 'error') {
				this.error = response.data.message ?? 'Unknown error.';
			}

			if (this.error !== '') {
				this.loading = false;
				return;
			}

			this.$store.commit(`host/${HostMutationTypes.CREATE_TRIVIA}`);
			this.$store.commit(
				`host/${HostMutationTypes.SET_GAME_ID}`,
				Number.parseInt(response?.data?.id ?? '0')
			);
			this.$router.push('/host/lobby');
			this.loading = false;
			this.error = '';
		},
		goBack() {
			if (window) {
				window.location.href =
					'https://rgaw-website.demo.circul8.com.au';
			}
		}
	}
});
