// @ts-ignore typescript-eslint/no-empty-interface
export interface RootState {
	modal: boolean;
}

export interface User {
	id: number;
	name: string;
	gameId: number | null;
	answers: Array<string>;
	points: number;
	currentTrivia: TriviaQuestion;
	currentAnswer?: string;
	sentimentCapture: SentimentCapture; // null
}

export enum SentimentCapture {
	NOT_SET,
	YES,
	NO,
	NOT_ANSWERED
}

export interface TriviaQuestion {
	images: Array<string>;
	isGamble: boolean;
	question: string;
	answers: Array<string>;
	correct?: string;
}

export enum RootMutationTypes {
	TOGGLE_MODAL = 'TOGGLE_MODAL'
}

export enum HostMutationTypes {
	SET_GAME_TYPE = 'SET_GAME_TYPE',
	SET_GAME_ID = 'SET_GAME_ID',
	ADD_PLAYER = 'ADD_PLAYER',
	REMOVE_PLAYER = 'REMOVE_PLAYER',
	RESET_HOST = 'RESET_HOST',
	ANSWER_QUESTION = 'ANSWER_QUESTION',
	NEXT_QUESTION = 'NEXT_QUESTION',
	CREATE_TRIVIA = 'CREATE_TRIVIA'
}

export enum JoinMutationTypes {
	SET_PLAYER = 'SET_PLAYER',
	SET_PLAYER_TRIVIA = 'SET_PLAYER_TRIVIA',
	SET_PLAYER_ANSWER = 'SET_PLAYER_ANSWER',
	SET_PLAYER_POINTS = 'SET_PLAYER_POINTS',
	SET_GAME_RESULTS = 'SET_GAME_RESULTS',
	SET_PLAYER_SENTIMENT = 'SET_PLAYER_SENTIMENT',
	START_GAME = 'START_GAME',
	RESET = 'RESET',
	RESET_PLAYER_TIME = 'RESET_PLAYER_TIME'
}

export enum SoloMutationTypes {
	SET_QUESTIONS = 'SET_QUESTIONS',
	ANSWER_QUESTION = 'ANSWER_QUESTION',
	NEXT_QUESTION = 'NEXT_QUESTION',
	SET_SENTIMENT = 'SET_SENTIMENT',
	RESET = 'RESET'
}

export enum GameTypes {
	SHORT = 3,
	MEDIUM = 5,
	LONG = 10
}
