
























import PusherJoinMixin from '@/components/mixins/PusherJoinMixin';
import JoinHeader from '@/components/JoinHeader.vue';

export default PusherJoinMixin.extend({
	components: { JoinHeader }
});
