










































import { JoinMutationTypes } from '@/types';
import PusherJoinMixin from '@/components/mixins/PusherJoinMixin';
import JoinHeader from '@/components/JoinHeader.vue';

export default PusherJoinMixin.extend({
	components: { JoinHeader },
	data: () => ({
		timer: 0,
		interval: null as ReturnType<typeof setTimeout> | null
	}),
	computed: {
		trivia() {
			return this.$store.state.join.player.currentTrivia;
		}
	},
	mounted() {
		this.$store.commit(`join/${JoinMutationTypes.RESET_PLAYER_TIME}`);

		if (!this.interval) {
			this.interval = setInterval(() => {
				this.timer++;
			}, 1000) as ReturnType<typeof setTimeout>;
		}
	},
	beforeDestroy() {
		if (!this.interval) {
			return;
		}

		clearInterval(this.interval);
	},
	methods: {
		sendAnswer(answer: string) {
			this.$channel?.trigger('client-answer', {
				answer,
				time: this.timer
			});

			this.$store.commit(`join/${JoinMutationTypes.SET_PLAYER_ANSWER}`, {
				answer,
				time: this.timer
			});
			this.$router.push('/join/game/processing');
		}
	}
});
