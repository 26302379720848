<template>
	<div class="group-game group-game_host">
		<HostHeader back-button :logo="false" @back="newGame()" />

		<main
			:class="[
				'site-main site-main_full',
				{ 'has-modal': $store.state.modal }
			]"
		>
			<div class="container-fluid">
				<h1 class="l-main-logo l-main-logo_medium">
					<a href="#" class="brand-logo-medium">
						<img
							src="/dist/images/logo.svg"
							alt=""
							class="img-fluid"
						/>
					</a>
				</h1>
				<!--/ .main logo -->

				<div class="my-auto">
					<div class="l-podium-container l-podium-container_group">
						<template v-for="(players, index) in leaderboard">
							<div
								:key="index"
								:class="[`podium-item order-${index + 1}`]"
							>
								<h3 class="podium-name">
									<span
										v-for="(player, k) in players"
										:key="k"
										class="d-block"
										v-text="player.name"
									/>
								</h3>
								<div
									:class="[
										'podium',
										{ podium_gold: index === 0 },
										{ podium_silver: index === 1 },
										{ podium_bronze: index === 2 }
									]"
								>
									<img
										v-if="index === 0"
										class="podium__ribbon"
										src="/dist/images/ribbon-gold-1st.svg"
										alt="1st"
									/>
									<img
										v-if="index === 1"
										class="podium__ribbon"
										src="/dist/images/ribbon-silver-2nd.svg"
										alt="2nd"
									/>
									<img
										v-if="index === 2"
										class="podium__ribbon"
										src="/dist/images/ribbon-bronze-3rd.svg"
										alt="3rd"
									/>
									<p>{{ podiums[index] }} points</p>
								</div>
							</div>
						</template>
					</div>
				</div>
				<!--/ .podium container -->

				<div class="l-banner-frame">
					<div class="banner-frame">
						<div class="col-left">
							<p>
								It’s hard to tell how your mates are really
								doing behind the screens.
								<br />
								Check in the next time you catch up.
							</p>
							<a
								href="https://rgaw-website.demo.circul8.com.au"
								target="_blank"
								class="btn btn-yellow btn-rounded"
							>
								Learn more
							</a>
						</div>
						<div class="col-right">
							<div class="row">
								<div class="col-6">
									<img
										class="logo-gaw-2020 img-fluid"
										src="/dist/images/logo-gaw-2020.svg"
										alt=""
									/>
								</div>
								<div class="col-6 text-right">
									<img
										class="logo-nsw img-fluid"
										src="/dist/images/logo-nsw-gov.svg"
										alt=""
									/>
								</div>
							</div>
						</div>
						<a
							href="https://rgaw-website.demo.circul8.com.au"
							target="_blank"
							class="btn btn-yellow btn-rounded d-block d-lg-none"
						>
							Learn more
						</a>
					</div>
				</div>
				<!--/ .banner-frame -->
			</div>
		</main>
		<!--/ .site-main -->
	</div>
</template>

<script>
import HostDebugbar from '@/components/HostDebugbar';
import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import { uniq, sortBy, size, map, filter } from 'lodash-es';

export default PusherHostMixin.extend({
	components: { HostDebugbar },
	computed: {
		podiums() {
			const { players } = this.$store.state.host;
			const maxPoints = uniq(sortBy(map(players, 'points')).reverse());
			return maxPoints.slice(0, 3);
		},
		leaderboard() {
			const { players } = this.$store.state.host;
			const sortedPlayers = sortBy(players, 'name');
			return this.podiums.map((points) =>
				filter(sortedPlayers, (player) => player.points === points)
			);
		}
	},
	methods: {
		size,
		newGame() {
			this.disconnect();
		},
		connected() {
			this.$channel.unbind('pusher:member_removed');
		}
	}
});
</script>
