








import PusherJoinMixin from '@/components/mixins/PusherJoinMixin';
import JoinMessage from '@/components/JoinMessage.vue';

export default PusherJoinMixin.extend({
	components: { JoinMessage }
});
