var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-game group-game_host"},[_c('HostHeader',{attrs:{"back-button":"","logo":false},on:{"back":function($event){return _vm.newGame()}}}),_c('main',{class:[
			'site-main site-main_full',
			{ 'has-modal': _vm.$store.state.modal }
		]},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"my-auto"},[_c('div',{staticClass:"l-podium-container l-podium-container_group"},[_vm._l((_vm.leaderboard),function(players,index){return [_c('div',{key:index,class:[("podium-item order-" + (index + 1))]},[_c('h3',{staticClass:"podium-name"},_vm._l((players),function(player,k){return _c('span',{key:k,staticClass:"d-block",domProps:{"textContent":_vm._s(player.name)}})}),0),_c('div',{class:[
									'podium',
									{ podium_gold: index === 0 },
									{ podium_silver: index === 1 },
									{ podium_bronze: index === 2 }
								]},[(index === 0)?_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-gold-1st.svg","alt":"1st"}}):_vm._e(),(index === 1)?_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-silver-2nd.svg","alt":"2nd"}}):_vm._e(),(index === 2)?_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-bronze-3rd.svg","alt":"3rd"}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.podiums[index])+" points")])])])]})],2)]),_vm._m(1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"l-main-logo l-main-logo_medium"},[_c('a',{staticClass:"brand-logo-medium",attrs:{"href":"#"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/dist/images/logo.svg","alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-banner-frame"},[_c('div',{staticClass:"banner-frame"},[_c('div',{staticClass:"col-left"},[_c('p',[_vm._v(" It’s hard to tell how your mates are really doing behind the screens. "),_c('br'),_vm._v(" Check in the next time you catch up. ")]),_c('a',{staticClass:"btn btn-yellow btn-rounded",attrs:{"href":"https://rgaw-website.demo.circul8.com.au","target":"_blank"}},[_vm._v(" Learn more ")])]),_c('div',{staticClass:"col-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"logo-gaw-2020 img-fluid",attrs:{"src":"/dist/images/logo-gaw-2020.svg","alt":""}})]),_c('div',{staticClass:"col-6 text-right"},[_c('img',{staticClass:"logo-nsw img-fluid",attrs:{"src":"/dist/images/logo-nsw-gov.svg","alt":""}})])])]),_c('a',{staticClass:"btn btn-yellow btn-rounded d-block d-lg-none",attrs:{"href":"https://rgaw-website.demo.circul8.com.au","target":"_blank"}},[_vm._v(" Learn more ")])])])}]

export { render, staticRenderFns }