



























































import Vue from 'vue';
import JoinHeader from '@/components/JoinHeader.vue';
import { SentimentCapture } from '@/types';

export default Vue.extend({
	components: { JoinHeader },
	props: {
		modal: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		SentimentCaptureEnum() {
			return SentimentCapture;
		}
	},
	methods: {
		setSentiment(sentiment: SentimentCapture) {
			this.$emit('sentiment', sentiment);
		}
	}
});
