



























































import AnswerTemplateMixin from '@/components/mixins/AnswerTemplateMixin';

export default AnswerTemplateMixin.extend({});
