import Vue from 'vue';
import App from './App.vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import VueGtm from 'vue-gtm';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Sentry.init({
	dsn:
		'https://1253fdc6dee44c1880a367d0c9bb89e1@o262082.ingest.sentry.io/5456740',
	integrations: [
		new VueIntegration({
			Vue,
			tracing: true,
			tracingOptions: {
				trackComponents: true
			},
			logErrors: process.env.NODE_ENV !== 'production'
		}),
		new Integrations.BrowserTracing()
	],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1,

	environment:
		process.env.NODE_ENV !== 'production'
			? 'development'
			: process.env.NODE_ENV
});

Vue.use(VueGtm, {
	id: 'GTM-PHTF6WZ',
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: true, // Whether or not display console logs debugs (optional)
	vueRouter: router
});

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
