



















import PusherJoinMixin from '@/components/mixins/PusherJoinMixin';
import Modal from '@/components/Modal.vue';
import { RootMutationTypes } from '@/types';

export default PusherJoinMixin.extend({
	components: { Modal },
	created() {
		// reset
	},
	props: {
		modal: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		toggleModal() {
			this.$store.commit(RootMutationTypes.TOGGLE_MODAL);
		}
	}
});
