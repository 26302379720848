





























































import Vue from 'vue';
import HostHeader from '@/components/HostHeader.vue';
import { SoloMutationTypes } from '@/types';

export default Vue.extend({
	components: { HostHeader },
	computed: {
		questionsCount: () => process.env.VUE_APP_SOLO_QUESTIONS
	},
	methods: {
		start() {
			this.$store.commit(`solo/${SoloMutationTypes.SET_QUESTIONS}`);
			this.$router.push('/solo/question');
		},
		goBack() {
			if (window) {
				window.location.href =
					'https://rgaw-website.demo.circul8.com.au';
			}
		}
	}
});
