var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solo-game"},[_c('HostHeader',{attrs:{"logo":false,"back-button":""},on:{"back":function($event){return _vm.mainMenu()}}}),_c('main',{class:[
			'site-main site-main_full',
			{ 'has-modal': _vm.$store.state.modal }
		]},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"my-auto"},[_c('div',{staticClass:"l-main-title l-main-title_results d-none d-lg-block"},[_c('h2',{staticClass:"h4 heavy"},[(_vm.correctAnswers / _vm.questionsCount < 0.7)?[_vm._v(" Thanks for playing. ")]:(
								_vm.correctAnswers / _vm.questionsCount < 0.8
							)?[_vm._v(" Nicely done! ")]:(
								_vm.correctAnswers / _vm.questionsCount < 0.9
							)?[_vm._v(" Hat’s off! ")]:[_vm._v(" You’re the champ! ")]],2)]),_c('div',{staticClass:"l-podium-container l-podium-container_solo"},[(_vm.correctAnswers / _vm.questionsCount < 0.7)?_c('div',{staticClass:"podium podium_place"},[_c('p',[_vm._v(_vm._s(_vm.correctAnswers)+" / "+_vm._s(_vm.questionsCount))])]):(_vm.correctAnswers / _vm.questionsCount < 0.8)?_c('div',{staticClass:"podium podium_bronze"},[_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-bronze.svg","alt":"3rd"}}),_c('p',[_vm._v(_vm._s(_vm.correctAnswers)+" / "+_vm._s(_vm.questionsCount))])]):(_vm.correctAnswers / _vm.questionsCount < 0.9)?_c('div',{staticClass:"podium podium_silver"},[_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-silver.svg","alt":"2nd"}}),_c('p',[_vm._v(_vm._s(_vm.correctAnswers)+" / "+_vm._s(_vm.questionsCount))])]):_c('div',{staticClass:"podium podium_gold"},[_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-gold.svg","alt":"1st"}}),_c('p',[_vm._v(_vm._s(_vm.correctAnswers)+" / "+_vm._s(_vm.questionsCount))])])]),_c('div',{staticClass:"l-main-cta l-main-cta_results"},[_c('div',{staticClass:"cta-item"},[_c('button',{staticClass:"btn btn-outline-white btn-large btn-rounded",on:{"click":function($event){return _vm.restart()}}},[_vm._v(" Play "),_c('span',{staticClass:"d-none d-lg-inline"},[_vm._v(" with new questions ")]),_c('span',{staticClass:"d-inline d-lg-none"},[_vm._v("again")])])]),_c('div',{staticClass:"cta-item"},[_c('button',{staticClass:"btn btn-white btn-large btn-rounded",on:{"click":function($event){return _vm.playMates()}}},[_vm._v(" Play with mates ")])])])]),_vm._m(1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"l-main-logo l-main-logo_medium"},[_c('a',{staticClass:"brand-logo-medium",attrs:{"href":"#"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/dist/images/logo.svg","alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-banner-frame"},[_c('div',{staticClass:"banner-frame"},[_c('div',{staticClass:"col-left"},[_c('p',[_vm._v(" It’s hard to tell how your mates are really doing behind the screens. "),_c('br'),_vm._v(" Check in the next time you catch up. ")]),_c('a',{staticClass:"banner-frame__cta-desktop",attrs:{"href":"https://rgaw-website.demo.circul8.com.au","target":"_blank"}},[_vm._v(" Learn more ")])]),_c('div',{staticClass:"col-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-lg-8 text-left"},[_c('img',{staticClass:"logo-gaw-2020 img-fluid",attrs:{"src":"/dist/images/logo-gaw-2020.svg","alt":""}})]),_c('div',{staticClass:"col-6 col-lg-4 text-right"},[_c('img',{staticClass:"logo-nsw img-fluid",attrs:{"src":"/dist/images/logo-nsw-gov.svg","alt":""}})])])]),_c('a',{staticClass:"banner-frame__cta-mobile",attrs:{"href":"https://rgaw-website.demo.circul8.com.au","target":"_blank"}},[_vm._v(" Learn more ")])])])}]

export { render, staticRenderFns }