var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-game group-game_player"},[_c('main',{class:[
			'site-main site-main_full',
			{ 'has-modal': _vm.$store.state.modal }
		]},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"l-join-form"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"#"}},[_vm._v(" Enter game pin ")]),_c('div',{class:[
							'form-control-wrap',
							{ error: _vm.errors.pin !== '' }
						]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pin),expression:"pin"}],staticClass:"form-control",attrs:{"id":"gamePin","type":"text","name":"gamePin","placeholder":"Game pin"},domProps:{"value":(_vm.pin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.pin=$event.target.value}}}),_c('span',{domProps:{"textContent":_vm._s(_vm.errors.pin)}})])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label",attrs:{"for":"nickname"}},[_vm._v(" Choose a nickname ")]),_c('div',{class:[
							'form-control-wrap',
							{ error: _vm.errors.nickname !== '' }
						]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nickname),expression:"nickname"}],staticClass:"form-control",attrs:{"id":"nickname","type":"text","name":"nickname","placeholder":"Nickname"},domProps:{"value":(_vm.nickname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nickname=$event.target.value}}}),_c('span',{domProps:{"textContent":_vm._s(_vm.errors.nickname)}})])])]),_c('div',{staticClass:"l-main-cta l-main-cta_player"},[_c('div',{staticClass:"cta-item"},[(_vm.loading)?_c('div',{staticClass:"btn btn-yellow btn-wide btn-medium btn-rounded disabled"},[_vm._v(" Loading … ")]):_c('button',{staticClass:"btn btn-yellow btn-wide btn-medium btn-rounded",attrs:{"disabled":!_vm.canJoin},on:{"click":function($event){$event.preventDefault();return _vm.join()}}},[_vm._v(" Join now ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"l-main-logo l-main-logo_player l-main-logo_start"},[_c('a',{staticClass:"brand-logo-medium_alt",attrs:{"href":"#"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/dist/images/logo.svg","alt":""}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-header-close"},[_c('a',{staticClass:"btn btn-close",attrs:{"href":"https://rgaw-website.demo.circul8.com.au"}},[_c('i',{staticClass:"icon icon-close"}),_c('span',{staticClass:"sr-only"},[_vm._v("Close")])])])}]

export { render, staticRenderFns }