






































































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import QuestionNoimgAbcd from '@/components/question-templates/QuestionNoimgAbcd.vue';
import QuestionImgAbcd from '@/components/question-templates/QuestionImgAbcd.vue';
import QuestionImgsAbcd from '@/components/question-templates/QuestionImgsAbcd.vue';
import QuestionImgAb from '@/components/question-templates/QuestionImgAb.vue';
import QuestionNoimgAb from '@/components/question-templates/QuestionNoimgAb.vue';
import HostDebugbar from '@/components/HostDebugbar.vue';
import { mapGetters } from 'vuex';

export default PusherHostMixin.extend({
	components: {
		HostDebugbar,
		QuestionNoimgAbcd,
		QuestionImgAbcd,
		QuestionImgsAbcd,
		QuestionImgAb,
		QuestionNoimgAb
	},
	data: () => ({
		timer: Number.parseInt(process.env.VUE_APP_QUESTION_TIMER as string),
		interval: undefined as undefined | ReturnType<typeof setInterval>
	}),
	computed: {
		...mapGetters('host', [
			'currentTrivia',
			'isCurrentQuestionAnsweredByAll'
		]),
		canGoNext() {
			return this.isCurrentQuestionAnsweredByAll;
		}
	},
	watch: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		canGoNext(newValue, _oldValue) {
			if (newValue) {
				this.$router.push('/host/question/results');
			}
		}
	},
	beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	},
	methods: {
		connected() {
			// Load the trivia question
			const { question, answers } = this.currentTrivia;
			this.$channel?.trigger('client-question', { question, answers });
			if (this.interval === undefined) {
				this.interval = setInterval(() => {
					this.timer--;
					if (this.timer <= 0) {
						this.timer = 0;
						this.$router.push('/host/question/results');
						clearInterval(
							this.interval as ReturnType<typeof setInterval>
						);
					}
				}, 1000) as ReturnType<typeof setInterval>;
			}
		}
	}
});
