var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-game group-game_host"},[_c('HostHeader',{attrs:{"close-button":"","game-info":"","timer":_vm.timer},on:{"close":function($event){return _vm.disconnect()}}}),_c('main',{class:[
			'site-main site-main_full',
			{ 'has-modal': _vm.$store.state.modal }
		]},[(_vm.currentTrivia)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"l-question-count"},[_c('h2',{staticClass:"h4 heavy"},[_vm._v(" Question "+_vm._s(_vm.$store.state.host.currentQuestionIndex + 1)+" / "+_vm._s(_vm.$store.state.host.questions.length)+" ")])]),(
					_vm.currentTrivia.images.length === 0 &&
						_vm.currentTrivia.answers.length > 2
				)?_c('QuestionNoimgAbcd',{attrs:{"trivia":_vm.currentTrivia}}):(
					_vm.currentTrivia.images.length === 1 &&
						_vm.currentTrivia.answers.length > 2
				)?_c('QuestionImgAbcd',{attrs:{"trivia":_vm.currentTrivia}}):(
					_vm.currentTrivia.images.length > 1 &&
						_vm.currentTrivia.answers.length > 2
				)?_c('QuestionImgsAbcd',{attrs:{"trivia":_vm.currentTrivia}}):(
					_vm.currentTrivia.images.length === 1 &&
						_vm.currentTrivia.answers.length <= 2
				)?_c('QuestionImgAb',{attrs:{"trivia":_vm.currentTrivia}}):_c('QuestionNoimgAb',{attrs:{"trivia":_vm.currentTrivia}})],1):_c('p',[_vm._v("Loading the question...")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }