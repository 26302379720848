import { Module } from 'vuex';
import {
	JoinMutationTypes,
	TriviaQuestion,
	User,
	RootState,
	SentimentCapture
} from '@/types';
import { GameResultsPayload } from '@/types/payloads';

interface JoinState {
	player: null | User;
	results: null | GameResultsPayload;
	started: boolean;
	lastAnswerTime: number;
}

const initJoinState = (): JoinState => ({
	player: null,
	results: null,
	started: false,
	lastAnswerTime: -1
});

export const JoinModule: Module<JoinState, RootState> = {
	namespaced: true,
	state: initJoinState(),
	mutations: {
		[JoinMutationTypes.SET_PLAYER](state: JoinState, player: User) {
			return (state.player = player);
		},

		// Update players current answer
		[JoinMutationTypes.SET_PLAYER_ANSWER](
			state: JoinState,
			{ answer, time }
		) {
			if (!state.player) {
				return;
			}

			state.player.currentAnswer = answer;
			state.lastAnswerTime = time;
			state.player = Object.assign({}, state.player);
		},

		// Update players current trivia question & answers
		[JoinMutationTypes.SET_PLAYER_TRIVIA](
			state: JoinState,
			trivia: TriviaQuestion
		) {
			if (!state.player) {
				return;
			}

			state.player.currentTrivia = trivia;
			state.player = Object.assign({}, state.player);
		},

		// Update player score / points
		[JoinMutationTypes.SET_PLAYER_POINTS](state: JoinState, points) {
			if (!state.player) {
				return;
			}

			state.player.points += points;
			state.player = Object.assign({}, state.player);
		},

		// Update player score / points
		[JoinMutationTypes.SET_PLAYER_SENTIMENT](
			state: JoinState,
			sentiment: SentimentCapture
		) {
			if (!state.player) {
				return;
			}

			state.player.sentimentCapture = sentiment;
			state.player = Object.assign({}, state.player);
		},

		// When the game finishes, receive the results.
		[JoinMutationTypes.SET_GAME_RESULTS](
			state: JoinState,
			payload: GameResultsPayload
		) {
			state.results = payload;
		},

		[JoinMutationTypes.START_GAME](state) {
			state.started = true;
		},

		[JoinMutationTypes.RESET](state) {
			Object.assign(state, initJoinState());
		},

		[JoinMutationTypes.RESET_PLAYER_TIME](state) {
			state.lastAnswerTime = -1;
		}
	},
	getters: {
		calculateAnswerPoints: (state) => {
			const maxPoints = Number.parseInt(
				process.env.VUE_APP_CORRECT_POINTS as string
			); // eg. 200 -> max points
			const questionTimer = Number.parseInt(
				process.env.VUE_APP_QUESTION_TIMER as string
			); // eg. 40 -> max seconds per question
			const time = state.lastAnswerTime;
			const points = maxPoints - time * (maxPoints / questionTimer);
			return points >= 0 ? points : 0;
		}
	}
};
