var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-game group-game_player bg-yellow"},[_c('main',{staticClass:"site-main site-main_full"},[_c('div',{staticClass:"container-fluid"},[(_vm.trivia)?_c('div',{staticClass:"my-auto"},[_c('button',{staticClass:"btn btn-close pt-3",on:{"click":function($event){return _vm.disconnect()}}},[_c('i',{staticClass:"icon icon-close"}),_c('span',{staticClass:"sr-only"},[_vm._v("Close")])]),_c('div',{class:[
						'l-question-answers-alt',
						{
							'l-question-answers-alt_abcd':
								_vm.trivia.answers.length !== 2
						},
						{
							'l-question-answers-alt_tf':
								_vm.trivia.answers.length === 2
						}
					]},_vm._l((_vm.trivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer-item"},[_c('button',{staticClass:"btn btn-answer",domProps:{"innerHTML":_vm._s(answer)},on:{"click":function($event){return _vm.sendAnswer(answer)}}})])}),0)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }