var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-game group-game_player"},[(
			_vm.$store.state.join.player.sentimentCapture ===
				_vm.sentimentCaptureEnum.NOT_SET
		)?_c('SentimentForm',{attrs:{"modal":true},on:{"sentiment":function (sentiment) { return _vm.setSentiment(sentiment); }}}):_c('main',{staticClass:"site-main site-main_full"},[_c('div',{staticClass:"container-fluid"},[_c('JoinHeader'),_c('div',{staticClass:"l-winner-name"},[_c('h2',{staticClass:"h6 fw-bold"},[_vm._v(" Awesome, "+_vm._s(_vm.$store.state.join.player.name)+" ")])]),_c('div',{staticClass:"l-podium-container l-podium-container_player"},[_c('div',{class:[
						'podium',
						{ podium_gold: _vm.position === 1 },
						{ podium_silver: _vm.position === 2 },
						{ podium_bronze: _vm.position === 3 },
						{ podium_place: _vm.position > 3 }
					]},[(_vm.position === 1)?_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-gold-1st.svg","alt":"1st"}}):_vm._e(),(_vm.position === 2)?_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-silver-2nd.svg","alt":"2nd"}}):_vm._e(),(_vm.position === 3)?_c('img',{staticClass:"podium__ribbon",attrs:{"src":"/dist/images/ribbon-bronze-3rd.svg","alt":"3rd"}}):_vm._e(),(_vm.position > 3)?_c('p',{staticClass:"lead fw-bold mb-3"},[_vm._v(" "+_vm._s(_vm.position)+" Place ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$store.state.join.player.points)+" points")]),_c('p',[_vm._v(" "+_vm._s(_vm.position)+" / "+_vm._s(_vm.$store.state.join.results.totals)+" ")])])]),_c('div',{staticClass:"l-banner-frame l-banner-frame_player"},[_c('div',{staticClass:"banner-frame"},[_c('div',{staticClass:"col-left"},[_c('p',[_vm._v(" It’s hard to tell how your mates are really doing behind the screens. "),_c('br'),_vm._v(" Check in the next time you catch up. ")])]),_c('div',{staticClass:"col-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"logo-gaw-2020 img-fluid",attrs:{"src":"/dist/images/logo-gaw-2020.svg","alt":""}})]),_c('div',{staticClass:"col-6 text-right"},[_c('img',{staticClass:"logo-nsw img-fluid",attrs:{"src":"/dist/images/logo-nsw-gov.svg","alt":""}})])])]),_c('a',{staticClass:"btn btn-yellow btn-rounded",attrs:{"href":"https://rgaw-website.demo.circul8.com.au","target":"_blank"}},[_vm._v(" Learn more ")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }