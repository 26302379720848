































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import { mapGetters } from 'vuex';

export default PusherHostMixin.extend({
	computed: {
		...mapGetters('host', ['roundNo'])
	}
});
