




































import QuestionTemplate from '@/components/mixins/QuestionTemplate';

export default QuestionTemplate.extend({});
