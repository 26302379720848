













































































































































































import Vue from 'vue';
import HostHeader from '@/components/HostHeader.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
	components: { HostHeader },
	computed: {
		...mapGetters('solo', ['questionsCount']),
		correctAnswers() {
			let points = 0;
			for (const index in this.$store.state.solo.questions) {
				const trivia = this.$store.state.solo.questions[index];
				if (trivia.correct === this.$store.state.solo.answers[index]) {
					points++;
				}
			}
			return points;
		}
	},
	methods: {
		mainMenu() {
			this.$store.dispatch('solo/reset');
			if (window) {
				window.location.href =
					'https://rgaw-website.demo.circul8.com.au';
			}
		},
		restart() {
			this.$store.dispatch('solo/reset');
			this.$router.push('/solo');
		},
		playMates() {
			this.$store.dispatch('solo/reset');
			if (window) {
				window.location.href =
					'https://rgaw-website.demo.circul8.com.au';
			}
		}
	}
});
