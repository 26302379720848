var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-qna-container l-qna-container_questions"},[_c('div',{staticClass:"l-question-box-alt"},[_c('p',{staticClass:"h4",domProps:{"innerHTML":_vm._s(_vm.trivia.question)}})]),_c('div',{staticClass:"l-answers-result"},[_c('div',{staticClass:"answers-result"},_vm._l((_vm.trivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answers-result__item"},[_c('div',{class:[
						'answer',
						{ correct: answer === _vm.trivia.correct }
					]},[_c('span',[_vm._v(_vm._s(_vm.countAnswers(answer)))]),_c('div',{staticClass:"answer-card",style:(("height: " + (_vm.countPercentage(answer) * 13.5) + "rem;"))})])])}),0)]),_c('div',{staticClass:"l-question-answers l-question-answers_abcd"},_vm._l((_vm.trivia.answers),function(answer,index){return _c('div',{key:index,staticClass:"answer-item"},[_c('div',{class:[
					'btn btn-answer',
					{ correct: answer === _vm.trivia.correct },
					{ incorrect: answer !== _vm.trivia.correct }
				],domProps:{"innerHTML":_vm._s(answer)}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }