import { find, filter } from 'lodash-es';
import Pusher from 'pusher-js';
import { JoinMutationTypes, TriviaQuestion } from '@/types';
import {
	ClientResultPayload,
	GameResultsPayload,
	PusherClientEventsMetadataPayload
} from '@/types/payloads';
import questions from '@/assets/trivia.json';
import Channel from 'pusher-js/types/src/core/channels/channel';
import { TriviaVue } from '@/types/TriviaVue';

Pusher.logToConsole = process.env.NODE_ENV !== 'production';

export default TriviaVue.extend({
	created() {
		if (window) {
			window.addEventListener('beforeunload', (event) => {
				event.preventDefault();
				event.returnValue = '';
			});
		}

		if (!this.$store.state.join.player) {
			this.$router.push('/join#lost-context');
			return;
		}

		let pusher: Pusher;
		if (!TriviaVue.prototype.$pusher) {
			pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY as string, {
				cluster: 'ap4',
				authEndpoint: `${process.env.VUE_APP_API}/auth`,
				auth: {
					params: {
						nickname: this.$store.state.join.player.name,
						userId: this.$store.state.join.player.id
					}
				}
			});
		} else {
			pusher = TriviaVue.prototype.$pusher;
		}

		let channel: Channel;
		if (!TriviaVue.prototype.$channel) {
			channel = pusher.subscribe(
				`presence-trivia_${this.$store.state.join.player.gameId}`
			);
		} else {
			channel = TriviaVue.prototype.$channel;
		}

		channel.bind('pusher:subscription_succeeded', () => {
			this.onPusherConnected();
		});

		// Host browser refresh fires this event too.
		// channel.bind('pusher:member_removed', (user) => {
		// 	if(user && parseInt(user.id) === 1) {
		// 		this.disconnect();
		// 	}
		// });

		if (!channel.callbacks.get('client-question')) {
			channel.bind(
				'client-question',
				(
					trivia: TriviaQuestion,
					metadata: PusherClientEventsMetadataPayload
				) => {
					if (metadata.user_id !== '1') {
						console.warn(
							'Wrong sender! Is someone trying to hack this?'
						);
						return;
					}

					const found = find(
						questions,
						(_trivia) => _trivia.question === trivia.question
					);
					if (!found) {
						console.warn(
							'Question not found. Is someone trying to hack this?'
						);
						return;
					}
					const foundAnswers = filter(
						found.answers,
						(answer) => !trivia.answers.includes(answer)
					).length;
					if (foundAnswers > 0) {
						console.warn(
							'Answers not found. Is someone trying to hack this?'
						);
						return;
					}

					this.$store.commit(
						`join/${JoinMutationTypes.SET_PLAYER_TRIVIA}`,
						trivia
					);
					if (this.$route.path !== '/join/game') {
						this.$router.push('/join/game');
					}
				}
			);
		}

		// Triggers when the host sends leaderboard...
		if (!channel.callbacks.get('client-leaderboard')) {
			channel.bind(
				'client-leaderboard',
				(
					payload: GameResultsPayload,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					metadata: PusherClientEventsMetadataPayload
				) => {
					this.$store.commit(
						`join/${JoinMutationTypes.SET_GAME_RESULTS}`,
						payload
					);
					if (this.$route.path !== '/join/game/results') {
						this.$router.push('/join/game/results');
					}
				}
			);
		}

		// Triggers when we are at the last question
		if (!channel.callbacks.get('client-last_question')) {
			channel.bind(
				'client-last_question',
				(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					payload: object,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					metadata: PusherClientEventsMetadataPayload
				) => {
					if (this.$route.path !== '/join/game/last-question') {
						this.$router.push('/join/game/last-question');
					}
				}
			);
		}

		if (!channel.callbacks.get('client-game_removed')) {
			channel.bind(
				'client-game_removed',
				(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					payload: object,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					metadata: PusherClientEventsMetadataPayload
				) => {
					if (this.$route.path !== '/join/game/results') {
						this.disconnect('game-removed');
					}
				}
			);
		}

		if (!channel.callbacks.get('client-game_started')) {
			channel.bind(
				'client-game_started',
				(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					payload: object,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					metadata: PusherClientEventsMetadataPayload
				) => {
					this.$store.commit(`join/${JoinMutationTypes.START_GAME}`);
				}
			);
		}

		if (!channel.callbacks.get('client-attention')) {
			channel.bind(
				'client-attention',
				(
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					payload: object,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					metadata: PusherClientEventsMetadataPayload
				) => {
					if (this.$route.path !== '/join/game/attention') {
						this.$router.push('/join/game/attention');
					}
				}
			);
		}

		if (!channel.callbacks.get('client-result')) {
			channel.bind(
				'client-result',
				(
					data: ClientResultPayload,
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					_metadata: PusherClientEventsMetadataPayload
				) => {
					if (
						this.$route.path === '/join/game/correct' ||
						this.$route.path === '/join/game/incorrect'
					) {
						return;
					}

					if (
						this.$store.state.join.player.currentAnswer ===
						data.correct
					) {
						const points = this.$store.getters[
							'join/calculateAnswerPoints'
						];

						this.$store.commit(
							`join/${JoinMutationTypes.SET_PLAYER_POINTS}`,
							points
						);
						this.$router.push('/join/game/correct');
					} else {
						this.$router.push('/join/game/incorrect');
					}
				}
			);
		}

		TriviaVue.prototype.$pusher = pusher;
		TriviaVue.prototype.$channel = channel;
	},
	methods: {
		onPusherConnected() {
			// Callback
		},
		disconnect(message = '') {
			// Disconnect from pusher
			TriviaVue.prototype.$pusher?.unsubscribe(
				`presence-trivia_${this.$store.state.join.player.gameId}`
			);
			TriviaVue.prototype.$pusher?.disconnect();

			TriviaVue.prototype.$pusher = null;
			TriviaVue.prototype.$channel = null;

			// Reset the state
			this.$store.commit(`join/${JoinMutationTypes.RESET}`);

			// Redirect
			this.$router.push(`/join#${message}`);
		}
	}
});
