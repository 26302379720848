






import Vue from 'vue';
import SentimentForm from '@/components/SentimentForm.vue';

import { SentimentCapture, SoloMutationTypes } from '@/types';
export default Vue.extend({
	components: { SentimentForm },
	computed: {
		SentimentCaptureEnum() {
			return SentimentCapture;
		}
	},
	methods: {
		saveSentiment(sentiment: SentimentCapture) {
			this.$store.commit(
				`solo/${SoloMutationTypes.SET_SENTIMENT}`,
				sentiment
			);
			this.$router.push('/solo/results');
		}
	}
});
