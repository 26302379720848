import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Play from '@/views/host/Play.vue';
import Lobby from '@/views/host/Lobby.vue';
import LastQuestionWarning from '@/views/host/LastQuestionWarning.vue';
import Question from '@/views/host/Question.vue';
import QuestionResults from '@/views/host/QuestionResults.vue';
import Results from '@/views/host/Results.vue';
import Video from '@/views/host/Video.vue';
import HostRemotely from '@/views/host/HostRemotely.vue';
import HostInPerson from '@/views/host/HostInPerson.vue';

import Join from '@/views/join/Join.vue';
import JoinLobby from '@/views/join/Lobby.vue';
import JoinGame from '@/views/join/Game.vue';
import Processing from '@/views/join/Processing.vue';
import Attention from '@/views/join/Attention.vue';
import Correct from '@/views/join/Correct.vue';
import Incorrect from '@/views/join/Incorrect.vue';
import JoinResults from '@/views/join/Results.vue';
import RoundAnnouncement from '@/views/host/RoundAnnouncement.vue';
import Leaderboard from '@/views/host/Leaderboard.vue';
import LastQuestion from '@/views/join/LastQuestion.vue';

import Solo from '@/views/solo/Solo.vue';
import SoloQuestion from '@/views/solo/SoloQuestion.vue';
import SoloResult from '@/views/solo/SoloResult.vue';
import SoloSentiment from '@/views/solo/SoloSentiment.vue';
import SoloResults from '@/views/solo/SoloResults.vue';
import Calculating from '@/views/host/Calculating.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	/**
	 * *****************************************
	 * HOST
	 * *****************************************
	 */
	{
		path: '/',
		component: Home
	},
	{
		path: '/host',
		redirect: '/host/play'
	},
	{
		path: '/host/how-to-remotely',
		component: HostRemotely
	},
	{
		path: '/host/how-to-in-person',
		component: HostInPerson
	},
	{
		path: '/host/play',
		component: Play
	},
	{
		path: '/host/lobby',
		component: Lobby
	},
	{
		path: '/host/video',
		component: Video
	},
	{
		path: '/host/round-announcement',
		component: RoundAnnouncement
	},
	{
		path: '/host/leaderboard',
		component: Leaderboard
	},
	{
		path: '/host/question',
		component: Question
	},
	{
		path: '/host/question/last',
		component: LastQuestionWarning
	},
	{
		path: '/host/question/results',
		component: QuestionResults
	},
	{
		path: '/host/calculating',
		component: Calculating
	},
	{
		path: '/host/results',
		component: Results
	},

	/**
	 * *****************************************
	 * CLIENT
	 * *****************************************
	 */
	{
		path: '/join',
		component: Join
	},
	{
		path: '/join/lobby',
		component: JoinLobby
	},
	{
		path: '/join/game',
		component: JoinGame
	},
	{
		path: '/join/game/processing',
		component: Processing
	},
	{
		path: '/join/game/attention',
		component: Attention
	},
	{
		path: '/join/game/correct',
		component: Correct
	},
	{
		path: '/join/game/incorrect',
		component: Incorrect
	},
	{
		path: '/join/game/results',
		component: JoinResults
	},
	{
		path: '/join/game/last-question',
		component: LastQuestion
	},

	/**
	 * *****************************************
	 * SOLO
	 * *****************************************
	 */
	{
		path: '/solo',
		component: Solo
	},
	{
		path: '/solo/question',
		component: SoloQuestion
	},
	{
		path: '/solo/question/result',
		component: SoloResult
	},
	{
		path: '/solo/sentiment',
		component: SoloSentiment
	},
	{
		path: '/solo/results',
		component: SoloResults
	}
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes
});

export default router;
