































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import HostHeader from '@/components/HostHeader.vue';

export default Vue.extend({
	components: { HostHeader },
	computed: {
		...mapGetters('host', ['isGameReady'])
	},
	mounted() {
		if (this.isGameReady) {
			this.$router.push('/host/lobby');
		}
	}
});
