import Vue from 'vue';

export class TriviaVue extends Vue {
	get $channel(): any {
		return this._$channel;
	}
	set $channel(value: any) {
		this._$channel = value;
	}
	private _$channel = null;

	get $pusher(): any {
		return this._$pusher;
	}
	set $pusher(value: any) {
		this._$pusher = value;
	}
	private _$pusher = null;
}
