










































import PusherJoinMixin from '@/components/mixins/PusherJoinMixin';
import VueLottiePlayer from 'vue-lottie-player';
import JoinHeader from '@/components/JoinHeader.vue';

export default PusherJoinMixin.extend({
	components: {
		vLottiePlayer: VueLottiePlayer,
		JoinHeader
	}
});
