






























import PusherHostMixin from '@/components/mixins/PusherHostMixin';

export default PusherHostMixin.extend({
	methods: {
		connected() {
			this.$channel?.trigger('client-last_question', { isLast: true });
		},
		nextQuestion() {
			this.$router.push('/host/question');
		}
	}
});
