



















import Vue from 'vue';
import JoinHeader from '@/components/JoinHeader.vue';

export default Vue.extend({
	components: { JoinHeader },
	props: {
		message: {
			type: String,
			required: true
		}
	}
});
