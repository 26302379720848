



















































































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import { isEmpty } from 'lodash-es';

export default PusherHostMixin.extend({
	data: () => ({
		pin: undefined
	}),
	methods: {
		connected() {
			this.pin = this.$store.state.host.gameId;
		},
		start() {
			this.$channel?.trigger('client-game_started', { started: true });
			this.$router.push('/host/video');
		},
		isEmpty
	}
});
