import Vue from 'vue';
import Pusher from 'pusher-js';
import axios from 'axios';
import { HostMutationTypes, SentimentCapture, User } from '@/types';
import HostHeader from '@/components/HostHeader.vue';
import {
	PusherClientEventsMetadataPayload,
	PusherMemberPayload
} from '@/types/payloads';
import { TriviaVue } from '@/types/TriviaVue';

Pusher.logToConsole = process.env.NODE_ENV !== 'production';

export default TriviaVue.extend({
	components: { HostHeader },
	created() {
		if (window) {
			window.addEventListener('beforeunload', (event) => {
				event.preventDefault();
				event.returnValue = '';
			});
		}

		if (TriviaVue.prototype.$channel) {
			console.info('Connection to Pusher exists.');
			this.connected();
			return;
		}

		const gameId = this.$store.state.host.gameId;
		if (gameId === null) {
			if (window) {
				window.location.href = 'https://www.checkmatetrivia.com';
			}
			return;
		}

		const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY as string, {
			cluster: 'ap4',
			authEndpoint: `${process.env.VUE_APP_API}/auth`,
			auth: {
				params: {
					nickname: 'host'
				}
			}
		});
		TriviaVue.prototype.$pusher = pusher;

		const channel = pusher.subscribe(`presence-trivia_${gameId}`);

		channel.bind('pusher:subscription_succeeded', () => {
			this.connected();
		});

		channel.bind('pusher:member_added', (member: PusherMemberPayload) => {
			const user = {
				id: member.id,
				name: member.info.name,
				gameId: member.info.gameId,
				answers: [
					...new Array(
						this.$store.state.host.gameType *
							Number.parseInt(
								process.env.VUE_APP_ROUNDS as string
							)
					)
				].map((_x) => ''),
				points: 0,
				sentimentCapture: SentimentCapture.NOT_SET
			} as User;
			this.$store.commit(`host/${HostMutationTypes.ADD_PLAYER}`, user);
		});

		channel.bind('pusher:member_removed', (member: PusherMemberPayload) => {
			if (member.id === 1) {
				// Cannot remove the host
				return;
			}
			this.$store.commit(
				`host/${HostMutationTypes.REMOVE_PLAYER}`,
				member.id
			);
			if (this.$store.state.host.gameId && member.info.name) {
				axios
					.delete(
						`${process.env.VUE_APP_API}/channel/user?user=${member.info.name}&channel=${this.$store.state.host.gameId}`
					)
					.catch((error) => {
						// Nothing.
					});
			}
		});

		channel.bind(
			'client-answer',
			(
				data: { answer: string; time: number },
				metadata: PusherClientEventsMetadataPayload
			) => {
				this.collectAnswer(data, metadata);
			}
		);

		TriviaVue.prototype.$channel = channel;
	},
	methods: {
		connected() {
			console.info('CONNECTED');
		},
		disconnect: async function() {
			TriviaVue.prototype.$channel?.trigger('client-game_removed', {
				gameId: this.$store.state.host.gameId
			});

			setTimeout(() => {
				// Timeout to propagate the 'client-game_removed' message
				TriviaVue.prototype.$pusher?.disconnect();
				TriviaVue.prototype.$pusher = null;
				TriviaVue.prototype.$channel = null;
			}, 1200);

			if (this.$store.state.host.gameId) {
				axios
					.delete(
						`${process.env.VUE_APP_API}/channel?channel=${this.$store.state.host.gameId}`
					)
					.catch((error) => {
						// Nothing.
					});
			}
			this.$store.commit(`host/${HostMutationTypes.RESET_HOST}`);
			if (window) {
				window.location.href = 'https://www.checkmatetrivia.com';
			}
		},
		collectAnswer(
			data: { answer: string; time: number },
			metadata: PusherClientEventsMetadataPayload
		) {
			const userId = metadata.user_id;
			const { answer, time } = data;
			this.$store.commit(`host/${HostMutationTypes.ANSWER_QUESTION}`, {
				userId,
				answer,
				time
			});
		}
	}
});
