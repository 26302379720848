































































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import VueLottiePlayer from 'vue-lottie-player';
import { map, uniq } from 'lodash-es';

export default PusherHostMixin.extend({
	components: {
		vLottiePlayer: VueLottiePlayer
	},
	data: () => ({
		timer: 20 as number,
		interval: undefined as undefined | ReturnType<typeof setInterval>
	}),
	mounted() {
		this.interval = setInterval(() => {
			this.timer--;
			if (this.timer <= 0) {
				this.goToLeaderboard();
			}
		}, 1000) as ReturnType<typeof setInterval>;

		const { players } = this.$store.state.host;
		const leaderboard = map(players, 'points');
		this.$channel?.trigger('client-leaderboard', {
			totals: leaderboard.length,
			leaderboard: uniq(leaderboard)
		});
	},
	methods: {
		goToLeaderboard() {
			clearInterval(this.interval as ReturnType<typeof setInterval>);
			this.$router.push('/host/results');
		}
	}
});
