











































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import HostHeader from '@/components/HostHeader.vue';
import GlobalEvents from 'vue-global-events';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { mapGetters } from 'vuex';

export default PusherHostMixin.extend({
	components: { vimeoPlayer: vueVimeoPlayer, HostHeader, GlobalEvents },
	computed: {
		...mapGetters('host', ['roundNo'])
	},
	methods: {
		connected() {
			this.$channel?.trigger('client-attention', { attention: true });
		},
		start() {
			this.$router.push('/host/round-announcement');
		},
		getVideoId() {
			switch (this.roundNo) {
				case 1:
					return '465683582';
				case 2:
					return '465700295';
				case 3:
					return '465700300';
				default:
					return '465700316';
			}
		}
	}
});
