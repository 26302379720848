


























































































































































































































































































import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import { SoloMutationTypes } from '@/types';
import { mapGetters } from 'vuex';
import HostHeader from '@/components/HostHeader.vue';

export default Vue.extend({
	components: {
		HostHeader,
		vLottiePlayer: VueLottiePlayer
	},
	computed: {
		...mapGetters('solo', [
			'currentTrivia',
			'currentQuestionNo',
			'questionsCount'
		]),
		selectedAnswer() {
			return this.$store.state.solo.answers[
				this.$store.state.solo.currentQuestionIndex
			];
		},
		isCorrect() {
			return this.currentTrivia.correct === this.selectedAnswer;
		}
	},
	methods: {
		goNext() {
			if (this.currentQuestionNo === this.questionsCount) {
				this.$router.push('/solo/sentiment');
			} else {
				this.$store.commit(`solo/${SoloMutationTypes.NEXT_QUESTION}`);
				this.$router.push('/solo/question');
			}
		},
		mainMenu() {
			this.$store.commit(`solo/${SoloMutationTypes.RESET}`);
			if (window) {
				window.location.href =
					'https://rgaw-website.demo.circul8.com.au';
			}
		}
	}
});
