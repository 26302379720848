










































import Vue from 'vue';
import { RootMutationTypes } from '@/types';
export default Vue.extend({
	methods: {
		exit() {
			this.$emit('exit');
			this.$store.commit(RootMutationTypes.TOGGLE_MODAL);
		},
		cancel() {
			this.$store.commit(RootMutationTypes.TOGGLE_MODAL);
		}
	}
});
