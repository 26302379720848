var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solo-game"},[_c('HostHeader',{attrs:{"close-button":""},on:{"close":function($event){_vm.$store.dispatch('solo/reset');
			_vm.goBack();}}}),_c('main',{class:[
			'site-main site-main_full',
			{ 'has-modal': _vm.$store.state.modal }
		]},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"l-question-count d-none d-lg-block"},[_c('h2',{staticClass:"h4 heavy"},[_vm._v(" Question "+_vm._s(_vm.currentQuestionNo)+" / "+_vm._s(_vm.questionsCount)+" ")])]),(
					_vm.currentTrivia.images.length === 0 &&
						_vm.currentTrivia.answers.length > 2
				)?_c('QuestionNoimgAbcd',{attrs:{"trivia":_vm.currentTrivia},on:{"answer":function (answer) { return _vm.setAnswer(answer); }}}):(
					_vm.currentTrivia.images.length === 1 &&
						_vm.currentTrivia.answers.length > 2
				)?_c('QuestionImgAbcd',{attrs:{"trivia":_vm.currentTrivia},on:{"answer":function (answer) { return _vm.setAnswer(answer); }}}):(
					_vm.currentTrivia.images.length > 1 &&
						_vm.currentTrivia.answers.length > 2
				)?_c('QuestionImgsAbcd',{attrs:{"trivia":_vm.currentTrivia},on:{"answer":function (answer) { return _vm.setAnswer(answer); }}}):(
					_vm.currentTrivia.images.length === 1 &&
						_vm.currentTrivia.answers.length <= 2
				)?_c('QuestionImgAb',{attrs:{"trivia":_vm.currentTrivia},on:{"answer":function (answer) { return _vm.setAnswer(answer); }}}):_c('QuestionNoimgAb',{attrs:{"trivia":_vm.currentTrivia},on:{"answer":function (answer) { return _vm.setAnswer(answer); }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }