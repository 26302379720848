import {
	GameTypes,
	HostMutationTypes,
	RootState,
	TriviaQuestion,
	User
} from '@/types';
import { Module } from 'vuex';
import { omit, shuffle } from 'lodash-es';
import questions from '@/assets/trivia.json';

const initHostState: HostState = {
	gameType: GameTypes.SHORT,
	gameId: null,
	players: {} as Record<string, User>,
	questions: [] as Array<TriviaQuestion>,
	currentQuestionIndex: 0
};

interface HostState {
	gameType: GameTypes;
	gameId: number | null;
	players: Record<string, User>;
	questions: Array<TriviaQuestion>;
	currentQuestionIndex: number;
}

export const HostModule: Module<HostState, RootState> = {
	namespaced: true,
	state: initHostState,
	mutations: {
		// The game type. 1 - short, 2 - medium, 3 - long
		[HostMutationTypes.SET_GAME_TYPE](state, gameType) {
			return (state.gameType = gameType);
		},

		// Mix questions and populate the questions pool.
		[HostMutationTypes.CREATE_TRIVIA](state) {
			const VUE_APP_ROUNDS = Number.parseInt(
				process.env.VUE_APP_ROUNDS as string
			);
			const questionsCount = state.gameType * VUE_APP_ROUNDS;
			const gambleQuestionsPerGame =
				Math.ceil(state.gameType / VUE_APP_ROUNDS) * VUE_APP_ROUNDS;

			let gambleQuestions = questions.filter(
				(question: TriviaQuestion) => question.isGamble
			);
			gambleQuestions = shuffle(gambleQuestions).slice(
				0,
				gambleQuestionsPerGame
			);

			let otherQuestions = questions.filter(
				(question: TriviaQuestion) => !question.isGamble
			);
			otherQuestions = shuffle(otherQuestions).slice(
				0,
				questionsCount - gambleQuestionsPerGame
			);

			gambleQuestions = shuffle(gambleQuestions);
			otherQuestions = shuffle(otherQuestions);

			const finalQuestions: Array<TriviaQuestion> = [];
			otherQuestions.forEach((question, index) => {
				finalQuestions.push(question);
				const when = Math.floor(
					otherQuestions.length / gambleQuestionsPerGame
				);
				if (index % when === 0) {
					const q = gambleQuestions.shift() as TriviaQuestion;
					if (q) {
						finalQuestions.push(q);
					}
				}
			});
			state.questions = finalQuestions;
		},

		[HostMutationTypes.ADD_PLAYER](state, player: User) {
			state.players[player.id] = player;
			state.players = Object.assign({}, state.players);
		},

		[HostMutationTypes.REMOVE_PLAYER](state, userId: string) {
			const players = omit(state.players, userId) as Record<string, User>;
			state.players = Object.assign({}, players);
		},

		[HostMutationTypes.RESET_HOST](state) {
			Object.assign(state, { ...initHostState, players: {} });
		},

		[HostMutationTypes.ANSWER_QUESTION](state, { answer, userId, time }) {
			const player = state.players[userId];
			if (player.answers.length === state.currentQuestionIndex) {
				player.answers.push(answer);
			} else {
				player.answers[state.currentQuestionIndex] = answer;
			}
			if (
				answer === state.questions[state.currentQuestionIndex].correct
			) {
				const maxPoints = Number.parseInt(
					process.env.VUE_APP_CORRECT_POINTS as string
				); // eg. 200 -> max points
				const questionTimer = Number.parseInt(
					process.env.VUE_APP_QUESTION_TIMER as string
				); // eg. 40 -> max seconds per question
				const points = maxPoints - time * (maxPoints / questionTimer);
				player.points += points >= 0 ? points : 0;
			}
			state.players[userId] = player;
			state.players = Object.assign({}, state.players);
		},

		[HostMutationTypes.NEXT_QUESTION](state) {
			state.currentQuestionIndex++;
		},

		// Game PIN number.
		[HostMutationTypes.SET_GAME_ID](state, id) {
			return (state.gameId = id);
		}
	},
	getters: {
		isGameReady: (state) =>
			state.gameType !== null &&
			state.gameId !== null &&
			state.questions.length > 0,
		currentTrivia: (state) => state.questions[state.currentQuestionIndex],
		currentQuestionNo: (state) => state.currentQuestionIndex + 1,
		questionsCount: (state) => state.questions.length,
		roundNo: (state, getters) =>
			Math.ceil(getters.currentQuestionNo / state.gameType),
		nextRoundNo: (state, getters) =>
			Math.ceil((getters.currentQuestionNo + 1) / state.gameType),
		isCurrentQuestionAnsweredByAll: (state) => {
			let isAnswered = true;
			const players = state.players;
			for (const playersId in players) {
				const answer =
					players[playersId].answers[state.currentQuestionIndex] ??
					false;
				if (!answer) {
					isAnswered = false;
				}
			}
			return isAnswered;
		}
	}
};
