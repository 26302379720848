












































































import Vue from 'vue';
import { SoloMutationTypes } from '@/types';
import { mapGetters } from 'vuex';
import HostHeader from '@/components/HostHeader.vue';
import QuestionNoimgAbcd from '@/components/question-templates/QuestionNoimgAbcd.vue';
import QuestionImgAbcd from '@/components/question-templates/QuestionImgAbcd.vue';
import QuestionImgsAbcd from '@/components/question-templates/QuestionImgsAbcd.vue';
import QuestionImgAb from '@/components/question-templates/QuestionImgAb.vue';
import QuestionNoimgAb from '@/components/question-templates/QuestionNoimgAb.vue';

export default Vue.extend({
	components: {
		HostHeader,
		QuestionNoimgAbcd,
		QuestionImgAbcd,
		QuestionImgsAbcd,
		QuestionImgAb,
		QuestionNoimgAb
	},
	computed: {
		...mapGetters('solo', [
			'currentTrivia',
			'currentQuestionNo',
			'questionsCount'
		])
	},
	created() {
		if (!this.questionsCount) {
			this.$router.push('/solo');
			return;
		}
		if (this.$store.state.solo.answers.length >= this.questionsCount) {
			this.$router.push('/solo/results');
			return;
		}
	},
	methods: {
		setAnswer(answer: string | number) {
			this.$store.commit(
				`solo/${SoloMutationTypes.ANSWER_QUESTION}`,
				answer
			);
			this.$router.push('/solo/question/result');
		},
		goBack() {
			if (window) {
				window.location.href =
					'https://rgaw-website.demo.circul8.com.au';
			}
		}
	}
});
