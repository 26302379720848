































import { User } from '@/types';
import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import { mapGetters } from 'vuex';

export default PusherHostMixin.extend({
	computed: {
		...mapGetters('host', ['isCurrentQuestionAnsweredByAll'])
	},
	methods: {
		getPlayerAnswer(player: User) {
			return (
				player.answers[this.$store.state.host.currentQuestionIndex] ??
				'-'
			);
		}
	}
});
