










































































































import PusherJoinMixin from '@/components/mixins/PusherJoinMixin';
import SentimentForm from '@/components/SentimentForm.vue';
import JoinHeader from '@/components/JoinHeader.vue';
import { findIndex, sortBy } from 'lodash-es';
import { JoinMutationTypes, SentimentCapture } from '@/types';

export default PusherJoinMixin.extend({
	components: { SentimentForm, JoinHeader },
	computed: {
		sentimentCaptureEnum() {
			return SentimentCapture;
		},
		position() {
			return (
				findIndex(
					sortBy(
						this.$store.state.join.results.leaderboard
					).reverse(),
					(point) => point <= this.$store.state.join.player.points
				) + 1
			);
		}
	},
	methods: {
		setSentiment(sentiment: SentimentCapture) {
			this.$store.commit(
				`join/${JoinMutationTypes.SET_PLAYER_SENTIMENT}`,
				sentiment
			);
		}
	}
});
