









































import PusherHostMixin from '@/components/mixins/PusherHostMixin';
import { sortBy } from 'lodash-es';
import { User } from '@/types';

export default PusherHostMixin.extend({
	computed: {
		leaderboard(): Array<User> {
			const { players } = this.$store.state.host;
			return sortBy(players, ['points'])
				.reverse()
				.slice(0, 10);
		},
		maxPoints(): number {
			return this.leaderboard[0].points;
		}
	},
	methods: {
		connected() {
			this.$channel?.trigger('client-attention', { attention: true });
		}
	}
});
